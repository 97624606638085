.button {
    font-family: 'DM Sans', sans-serif;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: rgba(255, 255, 255, 0.7);
    color: blue;
    text-decoration: underline;
}

.button:hover {
    background-color: rgba(255, 255, 255, 0.9);
    transform: scale(1.05);
}
