
.appHomeContainer {
  text-align: center;
  padding: 20px;
}

.buttonGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.pageButton {
  min-width: 0.75rem;
}

.buttonContainer{
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.75rem;
}

.icon {
  width: 1.5rem;
}
