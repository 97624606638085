.controleContainer {
  width: 22em;
  margin: 0 auto;
}

.card {
  background-color: var(--container-background);
  color: var(--text-color);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.petName {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.petPhoto {
  border: 0.3rem solid var(--yellow-logo);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
}

.label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.value {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  padding: 0.1rem 0.5rem;
}

p {
  font-size: 1.25rem;
  margin: 0;
  text-align: center;
}

.controleButtons {
  width: 15em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.buttons {
  font-size: 1rem;
  width: 8rem;
  padding: 1rem 0.8rem;
  transition: all 0.3s ease-in-out;
}

.buttons:hover {
  transform: scale(1.05);
}
