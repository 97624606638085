.registerContainer {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.registerForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.label {
  font-size: 14px;
}

.select {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid var(--button-hover-background);
}
