.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(5px); /* Suavização do fundo */
}
  
.modalContent {
    background-color: var(--container-background);
    color: var(--text-color);
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
}
  
.modalContent h2 {
    color: var(--text-color);
    font-size: 1.5rem;
    margin-bottom: 1rem;
}
  
.textarea {
    width: 90%;
    height: 150px;
    padding: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    font-size: 1rem;
    resize: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}
  
.textarea:focus {
    outline: none;
    border-color: var(--button-background); /* Cor de destaque ao focar */
    box-shadow: 0 0 0 2px var(--button-background); /* Efeito de destaque */
}
  
.buttonGroup {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }