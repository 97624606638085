:root {
  --text-color: #ffffff;
  --background-color: #121212;
  --button-color: #ffffff;
  --button-background: #2a2a2a;
  --button-hover-background: #333333;
  --container-background: #1f1f1f;
  --border-color: #ddd;
  --yellow-logo: #dfa430;
  --yellow-logo-dark: #b37a29;
  --red-pastel: #E06666;
  --green-pastel: #93C47D;
  --yellow-pastel: #FDE599;
  --red-pastel-hover: #EA9999;
  --green-pastel-hover: #B6D7A8;
  --yellow-pastel-hover: #FEF2CC;
}

.light {
  --text-color: #333333;
  --background-color: #f3f3f3;
  --button-color: #ffffff;
  --button-background: #e7b349;
  --button-hover-background: #868686;
  --container-background: #e6e6e6;
}

h2 {
  margin: 0;
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

body {
  font-family: 'DM Sans', 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  animation: fadeIn 1s ease-in;
}

@media (max-width: 768px) {
  body {
    font-size: 16px;
  }
  
  h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.75rem;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  h4 {
    font-size: 1.25rem;
  }
  
  h5 {
    font-size: 1rem;
  }
  
  h6 {
    font-size: 0.875rem;
  }
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

body {
  animation: fadeIn 1s ease-in;
}
