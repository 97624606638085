.usuariosContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--container-background);
  color: var(--text-color);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.usuariosTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.usuariosTable th,
.usuariosTable td {
  border: 1px solid var(--button-hover-background);
  padding: 10px;
  text-align: left;
}

.buttonContainer{
  display: flex;
  gap: 5px;
}


@media (max-width: 768px) {
  .emailColumn {
    display: none;
  }

  .usuariosTable th, .usuariosTable td {
    font-size: 0.8rem;
  }

  .usuariosContainer {
    max-width: 95vw;
  }
}
