/* Estilos gerais */
.registrosContainer {
  width: fit-content;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--container-background);
  color: var(--text-color);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Cabeçalho */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Botão de Alimentação */
.alimentacaoButton {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: linear-gradient(var(--yellow-logo), var(--yellow-logo-dark));
  position: relative;
  cursor: pointer;
  padding: 0.4rem;
  border: none;
  outline: none;
  overflow: hidden;
  box-shadow:
    inset 0 2px 3px rgba(255, 255, 255, 0.13),
    0 5px 8px rgba(0, 0, 0, 0.5),
    0 10px 10px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.alimentacaoButton:hover {
  cursor: pointer;
  transform: none;
  box-shadow:
    inset 0 2px 3px rgba(255, 255, 255, 0.13),
    0 5px 8px rgba(0, 0, 0, 0.5),
    0 10px 10px 4px rgba(0, 0, 0, 0.3);
}

.alimentacaoButton:focus,
.alimentacaoButton:active {
  outline: none;
  border: none;
}

.alimentacaoButton::after {
  content: "";
  position: absolute;
  left: -1rem;
  right: -1rem;
  top: -1rem;
  bottom: -1rem;
  z-index: -2;
  border-radius: inherit;
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.1),
    0 1px 2px rgba(0, 0, 0, 0.3),
    0 0 10px rgba(0, 0, 0, 0.15);
}

.alimentacaoButton::before {
  content: "";
  position: absolute;
  left: -0.5rem;
  right: -0.5rem;
  top: -0.5rem;
  bottom: -0.5rem;
  z-index: -1;
  border-radius: inherit;
  box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.13);
  filter: blur(1px);
}

/* Inner container */
.inner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(
    var(--yellow-logo-dark),
    var(--yellow-logo)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    0 -2px 5px rgba(255, 255, 255, 0.05),
    0 2px 5px rgba(255, 255, 255, 0.1);
}

.inner::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(#ffffff, #e0e0e0);
  border-radius: inherit;
  opacity: 0.1;
  pointer-events: none;
}

/* Estilização do SVG */
.alimentacaoButton .alimentacaoIcon {
  width: 2rem;
  height: 2rem;
  fill: white;
  mix-blend-mode: multiply;
  opacity: 0.75;
  transition: opacity 0.2s ease;
}

/* Efeito ao clicar (active) */
.alimentacaoButton:active {
  transform: scale(0.98) translateY(2px);
  box-shadow:
    inset 0 2px 3px rgba(255, 255, 255, 0.13),
    0 2px 4px rgba(0, 0, 0, 0.3),
    0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

/* Modal Animation */
@keyframes modalAppear {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalDisappear {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}

.modalAppear {
  animation: modalAppear 0.3s ease-out forwards;
  animation-delay: 0.15s;
}

.modalDisappear {
  animation: modalDisappear 0.3s ease-in forwards;
}

/* Título de localização */
.locationTitle {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
  color: var(--primary-color);
}

/* Tabela de registros */
.registrosTable {
  width: 100%;
  border-collapse: collapse;
}

.registrosTable th,
.registrosTable td {
  border: 1px solid var(--border-color);
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
}

th {
  padding: 0.5rem 0.25rem;
}

.registrosTable th:nth-child(1),
.registrosTable td:nth-child(1) {
  padding: 0px;
}

.registrosTable th {
  background-color: var(--button-background);
  color: white;
  font-size: 0.75rem;
}

.registrosTable td {
  background-color: var(--input-background);
  font-size: 0.625rem;
}

.petThumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  display: block;
}

.alimentacaoIcons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedingIcon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-right: 4px;
  transition: all 0.2s ease;
}

.commentIcon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.commentIcon:hover,
.feedingIcon:hover {
  transform: scale(1.1);
}

/* Modal de alimentação */
.modalContent {
  max-height: 70vh;
  overflow-y: auto;
  width: 100%;
  transition: all 0.3s ease;
}

/* Seleção de horário da refeição */
.mealTimeSelection {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.mealTimeRadio {
  margin: 0 10px;
  cursor: pointer;
  position: relative;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}

.mealTimeRadio label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--input-background);
  padding: 10px 20px;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  margin: 0;
}

.mealTimeRadio input[type="radio"]:checked + label {
  background-color: var(--button-background);
  color: white;
}

.mealTimeRadio label:hover {
  background-color: var(--button-hover-background);
}

/* Localizações (abas) */
.locationTabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.locationTab {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: var(--input-background);
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-weight: bold;
}

.locationTab:hover {
  background-color: var(--button-hover-background);
  color: white;
}

.activeLocationTab {
  background-color: var(--button-background);
  color: white;
}

/* Contêiner de pets */
.petsContainer {
  max-height: 50vh;
  overflow-y: auto;
  transition: max-height 0.3s ease;
}

/* Itens de alimentação do pet */
.petFeedingItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  align-items: center;
  margin-bottom: 10px;
  gap: 0.5rem;
}

.petFeedingItem span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
}

/* Seleção de status de alimentação */
.feedingStatusSelection,
.feedingStatusDisplay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedingStatusSelection label {
  margin-right: 5px;
  cursor: pointer;
}

.feedingStatusSelection input[type="radio"] {
  display: none;
}

.feedingStatusSelection img {
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.feedingStatusSelection input[type="radio"]:checked + img {
  opacity: 1;
}

.feedingStatusDisplay img {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

/* Input de horário */
.timeInputContainer {
  width: 100%;
}

.timeInput {
  width: 100%;
  border-radius: 20px;
}

.timeInput input {
  width: 100%;
  border-radius: 20px;
}

/* Input de observação */
.observationInputContainer {
  width: 100%;
}

.observationInput {
  width: 100%;
  border-radius: 20px;
}

.observationInput input {
  width: 100%;
  border-radius: 20px;
}

/* Estilização do Input de Horário quando desabilitado */
.timeInput input:disabled::placeholder {
  color: #ccc;
}

/* Estilização do Input de Observação quando desabilitado */
.observationInput input:disabled::placeholder {
  color: #ccc;
}

.modalContent {
  max-height: 70vh;
  overflow-y: auto;
  width: 100%;
  transition: all 0.3s ease;
}