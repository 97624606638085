.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
}

.modalContent {
  background-color: var(--container-background);
  color: var(--text-color);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  margin-top: 1.5rem;
}

.optionButton {
  flex: 1;
  min-width: 40%;
  padding: 1rem;
  
  
}

.optionButton:hover {
  transform: scale(1.1);
}

.buttonSim {
  background-color: var(--green-pastel);
  color: var(--text-color);
  border: none;
  transition: background-color 0.3s;
}

.buttonNao {
  background-color: var(--red-pastel);
  color: var(--text-color);
  border: none;
  transition: background-color 0.3s;
}

.buttonSim:hover {
  background-color: var(--green-pastel-hover);
}

.buttonNao:hover {
  background-color: var(--red-pastel-hover);
}

.backButton {
  margin-top: 1rem;
  background-color: var(--button-background);
}

.backButton:hover {
  background-color: var(--button-hover-background);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
