.userMenuContainer {
    position: fixed;
    top: 5vh;
    right: 4vh;
    z-index: 9999;
  }
  
  .userButton {
    background-color: var(--button-background);
    color: var(--button-color);
    border: none;
    font-size: 1.25rem;
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 3vh;
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .navUserButton:hover {
    transform: scale(1.05);
    background-color: var(--button-hover-background);
  }
  
  .dropdownMenu {
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items:flex-end;
    position: absolute;
    top: 7vh;
    right: 0;
    width: fit-content;
    background-color: var(--button-background);
    color: var(--text-color);
    border-radius: 0.5rem;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, visibility 0.5s ease;
  }
  
  .dropdownMenuOpen {
    visibility: visible;
    max-height: 70vh; /* Ajuste conforme a necessidade */
  }
  
  .dropdownMenu button {
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    margin: 0.25rem;
    width: 20vh;
    text-align: end;
    color: var(--text-color);
    cursor: pointer;
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .dropdownMenu button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 768px) {
    .userMenuContainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 20vw;
    }
  }