.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
  }
  
  .modal {
    background-color: var(--background-color);
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
    position: relative;
    opacity: 0;
    transform: translateY(-20px); /* Modal aparece de cima */
    animation: fadeIn 0.5s forwards; /* Animação suave */
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .closeButton {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    color: var(--red-pastel);
  }

  .closeButton:hover {
    color: var(--red-pastel-hover);
  }
  
  .modalTitle {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: var(--text-color);
  }
  
  .modalContent {
    margin-bottom: 20px;
    color: var(--text-color);
  }
  
  .modalFooter {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .cancelButton {
    background-color: var(--red-pastel);
    border-radius: 2rem;
    /* sombra no texto */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }

  .cancelButton:hover {
    background-color: var(--red-pastel-hover);
  }
  
  .confirmButton {
    background-color: var(--green-pastel);
    border-radius: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }

.confirmButton:hover {
    background-color: var(--green-pastel-hover);
}
  