.tableContainer {
  width: 100%;
  border-radius: 20px 20px 0 0;
  overflow-x: auto;
  margin-top: 0.5rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  /* color: var(--yellow-logo); */
}

.tableRow {
  height: 50px;
  border-radius: 20px;
  transition: all 1s ease;
}

.tableRow:nth-child(even) {
  transition: all 1s ease;
}

.tableRow:hover {
  border-radius: 20px;
  background-color: var(--background-color);
}

.tableCell {
  padding: 3px 10px;
  border-top: 1px solid var(--yellow-logo);
  color: var(--text-color);
  text-align: center;
}

@media (max-width: 1280px) {

  .tableHeader {
    font-size: 0.75rem;
  }

  .tableCell {
    padding: 3px;
    font-size: 0.75rem;
  }

}
