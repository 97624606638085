.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: var(--container-background);
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
    opacity: 0;
    transform: translateY(-20px); /* Modal aparece de cima */
    animation: fadeIn 0.5s forwards; /* Animação suave */
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .closeButton {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--red-pastel);
    transition: transform 0.3s ease-in-out;
  }

  .button{
    background-color: var(--button-background);
    color: var(--button-color);
    border: none;
    font-size: 1.25rem;
    width: 100%;
    padding: 1rem;
    border-radius: 3vh;
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .closeButton:hover {
    transform: scale(1.05);
  }

  .error {
    color: red;
    margin-bottom: 10px;
  }
  