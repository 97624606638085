/* styles/AlimentacaoModal.module.css */

.alimentacaoContainer {
    max-width: 90vw !important;
}

.alimentacaoModal {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
  }
  
  .mealTimeOptions,
  .feedingStatusOptions {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  .mealTimeOptions label,
  .feedingStatusOptions label {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }

  .alimentacaoLabel {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .alimentacaoInput {
    margin-right: 0.5rem;
  }

  .alimentacaoIcon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
  }
  
  .observations {
    width: 100%;
    height: 80px;
    resize: none;
  }
  