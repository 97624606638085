.notFoundContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.notFoundContainer p{
    font-size: 1.25rem;
    margin: 1rem;
}

.notFoundContainer ul{
    margin: 0.5rem;
}

.notFoundContainer button{
    margin: 1.5rem 0 1rem;
    padding: 0.5rem 1rem;
}
