.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background: var(--container-background);
    padding: 4rem;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    text-align: center;
    position: relative;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: var(--text-color);
    transition: color 0.3s ease;
  }
  
  .closeButton:hover {
    color: var(--yellow-logo);
  }
  
  .questionSection {
    margin-bottom: 20px;
  }
  
  .questionSection h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--text-color);
  }
  
  .questionSection p {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  
  .answerSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .answerButton {
    background: var(--button-background);
    color: var(--button-color);
    border: none;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    height: 4rem;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
  }
  
  .answerButton:hover {
    background: var(--button-hover-background);
    color: var(--yellow-logo);
  }
  
  .answerButton.selected {
    background: var(--yellow-logo);
    color: var(--background-color);
  }

  .answerButton.correct {
    background-color: var(--green-pastel);
  }
  
  .answerButton.incorrect {
    background-color: var(--red-pastel);
  }
  
  .scoreSection h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: var(--text-color);
  }
  
  .restartButton {
    background: var(--button-background);
    color: var(--button-color);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .restartButton:hover {
    background: var(--button-hover-background);
    transform: scale(1.05);
  }
  