.pontoPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: fit-content;
}

.pontoTitle {
  color: var(--yellow-logo);
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0 0 0;
  text-align: center;
}

.registerButton {
  background-color: var(--button-background);
  color: var(--text-color);
  border-radius: 2rem;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 1rem;
  transition: all 0.3s ease;
}

.registerButton:hover {
  background-color: var(--button-hover-background);
}

.confirmModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  position: relative;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalTitle {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;
}

.modalContent {
  margin-bottom: 20px;
  text-align: center;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
  margin-top: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.tableHeader, .tableCell {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.tableHeader {
  background-color: #f2f2f2;
  font-weight: bold;
}

.tableRow:hover {
  background-color: #f5f5f5;
}
