/* styles/CadastroTutor.module.css */

/* Estilos existentes */
.cadastroContainer {
  max-width: 90vw;
  padding: 40px;
  background-color: var(--container-background);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cadastroForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectContainer {
  display: flex;
  flex-direction: column;
}

.selectContainer label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.cadastroSelect {
  margin-bottom: 1rem;
  border-radius: 20px;
  padding: 0.5rem;
  border: 1px solid var(--button-hover-background);
  font-size: 1rem;
}

.buttonContainer {
  margin: 2rem 0 0;
  display: flex;
  justify-content: center;
}

/* Novos estilos para as listas de pets */
.petsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}

@media (min-width: 768px) {
  .petsContainer {
    flex-direction: row;
    justify-content: space-between;
  }
}

.availablePets,
.selectedPets {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.availablePets {
  margin-right: 0;
}

.selectedPets {
  margin-left: 0;
}

.petListContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.petListTitle {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.petList {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid var(--button-hover-background);
  border-radius: 10px;
  padding: 10px;
}

.petItem {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.petItem label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.petItem input[type="checkbox"] {
  margin-right: 10px;
}

/* Responsividade do formulário */
@media (min-width: 768px) {
  .cadastroContainer {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cadastroForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .buttonContainer {
    grid-column: span 2;
    display: flex;
    justify-content: center;
  }
}
