.loginContainer {
  max-width: 400px;
  margin: 100px auto;
  background-color: var(--container-background);
  color: var(--text-color);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
