.pageContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchBox {
  padding: 10px;
  border-radius: 20px;
  border: 1px solid var(--yellow-logo);
  background-color: var(--container-background);
  color: var(--text-color);
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
  transition: border-color 0.3s ease;
}

.searchBox:focus {
  outline: none;
  border-color: var(--button-background);
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
}

.card {
  background-color: var(--container-background);
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.imageContainer {
  overflow: hidden;
  aspect-ratio: 1;
}

.overlayImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  opacity: 0;
}

.breedImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.breedImage:hover {
  transform: scale(1.05);
}

.raca {
  margin: 0;
  padding: 0.5rem 0.5rem 0.25rem;
}

.destaque {
  margin: 0;
  padding: 0.25rem 0.5rem 0.5rem;
}

.button {
  padding: 0.5rem 0.75rem;
  margin: 0.5rem 0 1rem 0;
  font-size: 1rem;
}