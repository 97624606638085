.registrosContainer {
  width: fit-content;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--container-background);
  color: var(--text-color);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filters input {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  width: 100%;
}

.registrosTable {
  width: 100%;
  border-collapse: collapse;
}

.registrosTable th, .registrosTable td {
  border: 1px solid var(--border-color);
  text-align: center;
  cursor: pointer; /* para indicar que é clicável */
  vertical-align: middle; /* Alinhamento vertical */
}

th {
  padding: 0.5rem 0.25rem;
}


.registrosTable th:nth-child(1), .registrosTable td:nth-child(1){
  padding: 0px;
}

.registrosTable th {
  background-color: var(--button-background);
  color: white;
}

.registrosTable td {
  background-color: var(--input-background);
}

.petThumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  display: block;
}

.commentIcon {
  width: 24px; /* Tamanho do ícone */
  height: 24px;
  cursor: pointer; /* Cursor de clique */
  transition: all 0.2s ease; /* Transição suave ao passar o mouse */
}

.commentIcon:hover {
  transform: scale(1.1); /* Efeito de zoom ao passar o mouse */
}

@media (max-width: 1280px) {
  td {
    font-size: 0.625rem;
  }

  .registrosContainer {
    width: fit-content;
    max-width: 95%;
    margin: 0 auto;
  }

  .petThumbnail {
    width: 50px;
    height: 50px;
  }

  th {
    font-size: 0.75rem;
  }

}

