.main {
    /* min-height: calc(100vh - 16vh); */
    /* background-image: 'url(../assets/texture/background.png)'; */
    height: fit-content;
    overflow: auto;
    width: 100vw;
    position: relative;
    top: 16vh;
    padding: 1rem 0 4rem 0;
    margin: 0;
    /* background-color: var(--background-color); */
    color: var(--text-color);
    display: flex;
    justify-content: center;
}
