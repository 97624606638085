.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%; /* Garante que o container não exceda a largura do pai */
  margin: 0;
}

.label {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.input {
  box-sizing:border-box; /* Inclui padding e border na largura total */
  padding: 0.5rem;
  margin: 0;
  border: 1px solid var(--button-hover-background);
  border-radius: 20px;
  font-size: 1rem;
  width: 100%;
}

.input:disabled {
  box-sizing: border-box;
  padding: 0.5rem;
  border: 1px solid var(--button-hover-background);
  border-radius: 20px;
  font-size: 1rem;
  background-color: #cccccc;
  width: 100%;
  color: black;
  cursor: not-allowed;
}
