.menuContainer {
  position: relative;
  z-index: 9999;
  width: 0;
}

.hamburger {
  background-color: var(--button-background);
  color: var(--button-color);
  border: none;
  border-radius: 50%;
  font-size: 6vh;
  width: 8vh;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  position: fixed;
  left: 4vh;
  top: 4vh;
}

.hamburger:hover {
  background-color: var(--button-background);
}

/* Garantir que a rotação seja aplicada independentemente do estado de hover */
.hamburger.hamburgerOpen,
.hamburger:hover.hamburgerOpen {
  transform: rotate(90deg);
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.3);
}

.nav {
  visibility: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--button-background);
  position: fixed;
  top: 13vh;
  left: 4vh;
  min-width: 20vh;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.navOpen {
  visibility: visible;
  max-height: 70vh;
}

.ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.li {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}

.li:hover {
  background-color: var(--button-hover-background);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
}

.link {
  color: var(--button-color);
  text-decoration: none;
  font-size: 1rem;
}