.footer {
  background-color: var(--button-background);
  color: var(--button-color);
  text-align: center;
  padding: 1rem 0;
  margin: 0;
  position: fixed;
  bottom: 0;
  height: 3vh;
  width: 100vw;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .footer {
    font-size: 0.8rem;
  }
}