.card {
  background-color: var(--container-background);
  color: var(--text-color);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18rem;
  max-width: 100%;
  margin: 10px;
}

.petName {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.petPhoto {
  border: 0.3rem solid #DFA430;
  box-shadow: 0 0 8px #DFA430;
  object-fit: cover;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.value {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  /* background-color: var(--button-background); */
  border-radius: 1rem;
  padding: 0.1rem 0.5rem;
}

.selectButton {
  background-color: var(--button-background);
  color: var(--button-color);
  padding: 0.5rem 1rem;
  border: none;
  font-size: 1.25rem;
  border-radius: 3rem;
  cursor: pointer;
  transition: all 0.3s;
}

.selectButton:hover {
  background-color: var(--button-hover-background);
  transform: scale(1.05);
}
