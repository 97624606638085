button {
  background-color: var(--button-background);
  color: var(--button-color);
  border: none;
  border-radius: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

button:hover {
  background-color: var(--button-hover-background);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
  transition: none;
}

button:disabled:hover {
  background-color: #aaa;
  transform: scale(1)
}

button.buttonSim {
  background-color: var(--green-pastel);
  color: var(--text-color);
  border: none;
  transition: background-color 0.3s;
}

button.buttonNao {
  background-color: var(--red-pastel);
  color: var(--text-color);
  border: none;
  transition: background-color 0.3s;
}

button.buttonSim:hover {
  background-color: var(--green-pastel-hover);
}

button.buttonNao:hover {
  background-color: var(--red-pastel-hover);
}