.header {
  background-color: var(--button-background);
  color: var(--button-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
  height: 16vh;
  padding: 0;
  margin: 0;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo img {
  height: 12vh;
}
