.cadastroContainer {
  max-width: 90vw;
  padding: 40px;
  background-color: var(--container-background);
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cadastroForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (min-width: 768px) {
  .cadastroContainer {
    max-width: 800px;
    margin: 0 auto;
  }
  .cadastroForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}