.mascotesContainer {
  max-width: 90%;
  box-sizing: border-box;
}

.searchContainer {
  background-color: var(--button-background);
  border-radius: 20px;
  padding: 20px;
  margin: 0.5rem auto 1rem;
  width: 90%;
}

label {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 1rem;
}

.cardsContainer {
  background-color: var(--button-background);
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  padding: 20px;
  width: 90%;

}

.mascoteCard {
  margin-bottom: 20px;
}
