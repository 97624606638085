.popupContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Fundo semi-transparente */
    backdrop-filter: blur(8px); /* Suavização do fundo */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .popupContent {
    background-color: var(--container-background);
    color: var(--text-color);
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    width: 800px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.25rem;
    background-color: var(--red-pastel);
    border: none;
    border-radius: 5px;
    color: var(--text-color);
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .closeButton:hover {
    background-color: var(--red-pastel-hover);
  }
  
  .title {
    margin-bottom: 0.5rem;
    font-size: 2rem;
    color: var(--yellow-logo);
  }
  
  .subtitle {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
    color: var(--text-color);
  }

  .sectionTitle {
    margin: 1rem;
    font-size: 1.5rem;
    color: var(--yellow-logo);
  }
  
  .detailItem {
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  .detailItem strong {
    color: var(--yellow-logo);
  }
  
  .detailItem span {
    color: var(--text-color);
  }